import React from 'react';
import styled from 'styled-components';
import {GridComponentContainer} from '../components/atoms';
import {Header, Intro, SEO} from '../components/organisms';
import NewsItemPreview from '../components/organisms/blocks/NewsItemPreview';
import {PageContent} from '../components/organisms/Layout';
import useNewsData from '../queries/useNewsData';
import {NavigationContext} from '../services/navigation';
import {devices} from '../styles/theme';

const ThumbnailsContainer = styled(GridComponentContainer)`
  display: grid;
  grid-template-columns: 1fr;
  @media ${devices.tabletPortrait} {
    grid-template-columns: repeat(4, 1fr);
  }
  @media ${devices.tabletLandscape} {
    grid-template-columns: repeat(6, 1fr);
  }
`;

const News = () => {

  const {setLocation} = React.useContext(NavigationContext);
  React.useEffect(() => {
    setLocation(window.location.pathname);
  }, []);

  const {newsOverview, newsItems} = useNewsData();
  const {header, intro} = newsOverview || {};

  return (
    <PageContent>
      <SEO
        title={newsOverview?.SEO?.title}
        description={newsOverview?.SEO?.description}
        meta={[
          {
            name: 'keywords',
            content: newsOverview?.SEO?.keywords,
          },
          {
            property: 'og:image',
            content: `${process.env.GATSBY_CLIENT_ORIGIN}${header?.media?.file?.file?.publicURL}`
          }
        ]}
      />
      <Header header={header}/>
      <Intro intro={{...intro, startingColumn: 2}}/>
      <ThumbnailsContainer fromColumn={2} widthInColumns={6}>
        {newsItems.map(newsItem => (
          <NewsItemPreview key={newsItem.id} newsItem={newsItem}/>
        ))}
      </ThumbnailsContainer>
    </PageContent>
  );
};

export default News;
