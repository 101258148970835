import {graphql, useStaticQuery} from 'gatsby';

const useNewsData = () => {
  const data = useStaticQuery(graphql`
    query {
      strapi {
        newsOverview {
          SEO {
            ...SEO
          }
          header {
            ...RegularHeader
          }
          intro {
            ...Intro
          }
        }
        newsItems (sort: "created_at:desc") {
          ...NewsItem
        }
      }
    }
  `);

  return data.strapi;
};

export default useNewsData;
